import {useSharedTranslations} from '@/hooks/useTranslations';

interface Props {
  copy?: string;
}

export function NewBadge({copy}: Props) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');
  return (
    <span className="bg-[#F0F1F2] inline-block w-max py-1 px-2 font-bold rounded text-xs">
      {copy ?? t(`listing.profileCard.new`)}
    </span>
  );
}
