import {type z} from 'zod';

import {type PageInfo} from '../types';

import {type contactFormValidator} from './validators/contactFormValidator';

export type ContactForm = z.infer<typeof contactFormValidator>;

export interface ServiceOffering {
  description: string;
  featured: boolean;
  pricingRange: {
    minPrice: string;
    maxPrice: string;
  };
  pricingType: string;
  service: {
    description: string;
    handle: string;
    label: string;
  };
}

interface Country {
  countryCode: string;
  name: string;
}

export interface Industry {
  label: string;
  handle: string;
}

interface Language {
  label: string;
  code: string;
}

export interface RatingBucket {
  label: string;
  numberOfRatings: number;
}

export interface SocialMediaLink {
  type: 'INSTAGRAM' | 'LINKEDIN' | 'FACEBOOK' | 'TWITTER';
  url: string;
}

export interface FeaturedWork {
  description: string;
  title: string;
  url: string;
}

export interface Shop {
  publicId: string;
  webUrl: string;
  name: string;
  status: string;
}

export interface Profile {
  handle: string;
  name: string;
  bio: string;
  websiteUrl: string;
  acceptingClients: boolean;
  avatarUrl: string;
  contactEmail: string;
  contactPhoneNumber: string;
  city: string;
  country: Country;
  createdAt: string;
  secondaryCountries: Country[];
  industries: Industry[];
  languages: Language[];
  averageRating: number;
  averageCommunicationRating: number;
  averageQualityOfWorkRating: number;
  numberOfRatings: number;
  ratingBuckets: RatingBucket[];
  socialMediaLinks: SocialMediaLink[];
  partnerSince: string;
  serviceOfferings: ServiceOffering[];
  featuredWorks: FeaturedWork[];
  ratings: {
    nodes: Review[];
    pageInfo: PageInfo;
  };
  tags: Tags[];
}

export interface User {
  sub: string;
  family_name: string;
  given_name: string;
  locale: string;
  name: string;
  nickname: string;
  updated_at: number;
  zoneinfo: string;
  tfa_enabled: boolean;
  email: string;
  email_verified: boolean;
}

export interface Review {
  reviewerName: string;
  communicationRating: number;
  submittedAt: string;
  qualityOfWorkRating: number;
  service?: {
    label: string;
  };
  text: string | null;
}

export enum FormIntent {
  LOGIN = `login`,
  FETCH_SHOPS = `fetchShops`,
  FETCH_REVIEWS = `fetchReviews`,
  CONTACT_PARTNER = `contactPartner`,
}

export enum Tags {
  PLUS_TAG = `PLUS`,
}
