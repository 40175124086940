// This component is meant to show a skeleton of the component that is being loaded.
// It can take a prop to show a different skeleton for different components.

import {twMerge} from '@/stylesheets/twMerge';

interface SkeletonProps {
  type: 'circle' | 'rectangle' | 'text';
  className?: string;
}

export function Skeleton({type, className}: SkeletonProps) {
  if (type === 'circle') {
    return (
      <div
        className={twMerge(
          'rounded-full w-full h-full bg-gray-200 animate-pulse',
          className,
        )}
      />
    );
  } else if (type === 'rectangle') {
    return (
      <div
        className={twMerge(
          'rounded-md w-80 h-40 bg-gray-200 animate-pulse',
          className,
        )}
      />
    );
  } else if (type === 'text') {
    return (
      <div
        className={twMerge(
          'rounded-md bg-gray-200 w-full h-[1.1em] inline-block animate-pulse',
          className,
        )}
      />
    );
  } else {
    return null;
  }
}
