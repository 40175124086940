import {useSharedTranslations} from '@/hooks/useTranslations';

import {Tags} from '../../partner/types';

interface ProfileTagProps {
  tag?: string;
}

export function ProfileTag({tag}: ProfileTagProps) {
  const {t} = useSharedTranslations('pages/partners/directory/shared');

  if (tag === Tags.PLUS_TAG) {
    return (
      <span className="inline-flex items-center rounded-md bg-black px-2 py-1 text-xs font-medium text-white">
        {t(`listing.profileCard.plusPartnerTag`)}
      </span>
    );
  }

  return <></>;
}
